<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell">
          <div class="table__cell-inner">
            <div v-if="info.northSouth" class="indicator indicator--opacity indicator--orange">N</div>
            <div v-else class="indicator indicator--opacity indicator--blue">S</div>
          </div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.businessName }}</div>
          <!-- <div class="table__cell-subtitle">{{ info.email }}</div> -->
        </div>
        <div class="table__cell">
          <!-- <div class="table__cell-title">{{ info.province }} {{ info.city }}</div> -->
          <!-- <div class="table__cell-title">{{ info.postalCode }} {{ info.address }}</div> -->
          <div class="table__cell-title">{{ info.address }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.firstName }} {{ info.lastName }}</div>
        </div>
        <div class="table__cell">
          <!-- <div class="table__cell-subtitle">
        HP: <span>{{ info.homePhone }}</span>
      </div> -->
          <div class="table__cell-title table__cell--link" @click.stop>
            <a :href="'tel:' + info.cellPhone">{{ info.cellPhone }}</a>
          </div>
        </div>
        <!-- <div class="table__cell table__cell--coordinate" @click.stop="doCopy">
      <div class="table__cell-subtitle">
        Long: <span>{{ info.lng }}</span>
      </div>
      <div class="table__cell-subtitle">
        Lat: <span>{{ info.lat }}</span>
      </div>
    </div> -->
        <!-- <div class="table__cell table__cell--end" @click.stop>
      <Dropdown :v-model="value" :options="options" @action="action">
        <i class="ri-more-fill"></i>
      </Dropdown>
    </div> -->
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="letter">{{ info.businessName[0] }}</div>
          </div>
          <div class="card__box">
            <div class="card__title">{{ info.businessName }}</div>
            <div class="card__subtitle">{{ info.businessPhone }}</div>
          </div>
          <div class="card__box" @click.stop.self>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit">
                  <a v-if="info.businessPhone" class="card__link" :href="'tel:' + info.businessPhone">
                    <i class="ri-phone-fill"></i>Call
                  </a>
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i> Edit
                </el-dropdown-item>
                <el-dropdown-item class="item--remove" command="Remove">
                  <i class="ri-delete-bin-7-fill"></i> Remove
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import Dropdown from '@/components/Dropdown'
import Modal from '@/components/ToggleModal'

export default {
  name: 'PublicCustomer',
  components: {
    ToggleButton,
    Dropdown
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    value: 'dropdown',
    options: ['Edit', 'Remove']
  }),
  methods: {
    async action(act) {
      if (act === 'Edit') {
        this.$root.$emit('ShowSidebar', {
          sidebarName: 'AddCustomer',
          componentData: this.info
        })
      } else if (act === 'Remove') {
        try {
          await this.$store.dispatch('customers/delete', this.info.id)
          this.$store.commit('customers/change', this.info.id)
        } catch (e) {}
      }
    },
    toggleModal(info) {
      const style = this.$modal.styles
      this.$modal.show(
        Modal,
        {info, type: 'customers'},
        {...style, name: 'ToggleModal', height: '140px'},
        {
          'before-open': this.$modal.open,
          'before-close': this.$modal.close
        }
      )
    },
    doCopy() {
      this.$copyText(this.info.lng + ',' + this.info.lat).then(
        function(err) {
          alert('Copied')
          console.error(err)
        },
        function(err) {
          alert('Can not copy')
          console.error(err)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
