<template>
  <div class="modal__inner">
    <div class="modal__title">
      Are you sure you want to
      <span v-if="!!info.isActive"> deactivate</span>
      <span v-else> activate </span>
      <span>{{ info.name }}?</span>
    </div>
    <div class="modal__buttons">
      <button class="button button--fill" @click="changeActive">
        <span v-if="!!info.isActive"> Deactivate</span>
        <span v-else> Activate </span>
      </button>
      <button class="button" @click="$modal.hide('ToggleModal')">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleModal',
  props: {
    info: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  methods: {
    async changeActive() {
      try {
        if (this.info.isActive) {
          await this.$store.dispatch(`${this.type}/deactivate`, this.info.id)
        } else {
          await this.$store.dispatch(`${this.type}/activate`, this.info.id)
        }
        this.info.isActive = !this.info.isActive
        this.$store.commit(`${this.type}/change`, this.info)
        this.$modal.hide('ToggleModal')
      } catch (e) {
        alert(e.response.data.error)
      }

      // try {
      //   if (this.info.isActive) {
      //     await this.$store.dispatch('companies/deactivate', this.info.id)
      //   } else {
      //     await this.$store.dispatch('companies/activate', this.info.id)
      //   }
      //   this.info.isActive = !this.info.isActive
      //   this.$store.commit('companies/changeCompany', this.info)
      //   this.$modal.hide('ToggleModal')
      // } catch (e) {
      //   alert(e.response.data.error)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
