var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__inner" }, [
    _c("div", { staticClass: "modal__title" }, [
      _vm._v(" Are you sure you want to "),
      !!_vm.info.isActive
        ? _c("span", [_vm._v(" deactivate")])
        : _c("span", [_vm._v(" activate ")]),
      _c("span", [_vm._v(_vm._s(_vm.info.name) + "?")])
    ]),
    _c("div", { staticClass: "modal__buttons" }, [
      _c(
        "button",
        { staticClass: "button button--fill", on: { click: _vm.changeActive } },
        [
          !!_vm.info.isActive
            ? _c("span", [_vm._v(" Deactivate")])
            : _c("span", [_vm._v(" Activate ")])
        ]
      ),
      _c(
        "button",
        {
          staticClass: "button",
          on: {
            click: function($event) {
              return _vm.$modal.hide("ToggleModal")
            }
          }
        },
        [_vm._v("Cancel")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }