var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "table__main" },
          _vm._l(_vm.customers, function(customer) {
            return _c("Customer", {
              key: customer.id,
              attrs: { info: customer },
              nativeOn: {
                click: function($event) {
                  return _vm.$router.push("/users/customers/" + customer.id)
                }
              }
            })
          }),
          1
        )
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table__title-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "table__title-inner" }, [
          _c("div", { staticClass: "table__title-item" }, [_vm._v("N/S")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Business Name")
          ]),
          _c("div", { staticClass: "table__title-item" }, [_vm._v("Address")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Contact Name")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Cell Phone")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }